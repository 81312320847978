var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('side-content',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Propostas "),_c('v-spacer'),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"#E5E5EA","fab":"","depressed":"","x-small":"","to":{ name: 'CaseShow' }}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" close ")])],1)]},proxy:true}])},[(_vm.loading > 0)?_c('loading',{staticClass:"absolute-center"}):_c('v-layout',{staticClass:"mt-0",attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"py-0 pa-0",attrs:{"xs12":"","sm12":""}},[(_vm.proposals.length > 0)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.proposals,"single-expand":true,"expanded":_vm.expanded,"hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
return [_c('tr',{class:{'table-list-opened': isExpanded },attrs:{"title":"Exibir detalhes"}},[_c('td',{staticClass:"px-2 caption",on:{"click":function($event){return _vm.toggleExpand(item)}}},[_vm._v(" "+_vm._s(_vm.$d(item.insertedAt.toDate(), 'date'))+" ")]),_c('td',{staticClass:"px-2 text-center caption",on:{"click":function($event){return _vm.toggleExpand(item)}}},[(['created', 'visualized'].includes(item.state))?_c('span',[_vm._v(" "+_vm._s(item.daysExpire)+" dia(s) ")]):_c('span',[_vm._v(" - ")])]),_c('td',{staticClass:"px-2 text-center caption",on:{"click":function($event){return _vm.toggleExpand(item)}}},[_vm._v(" "+_vm._s(_vm.$t('proposal.state.' + item.state))+" ")]),_c('td',{staticClass:"px-0"},[_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":"","small":""}},on),[_c('v-icon',{attrs:{"size":"26px","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$root.icons.layout.more_y)+" ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"disabled":_vm.ticketsSize === 1},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-list-item-title',[_vm._v("Remover Título")])],1),(_vm.canIgnore)?_c('v-list-item',{attrs:{"disabled":_vm.ticketsSize === 1},on:{"click":function($event){return _vm.change(item, !item.ignoreOnInitialProposal)}}},[(!item.ignoreOnInitialProposal)?_c('v-list-item-title',[_vm._v(" Ignorar Título ")]):_c('v-list-item-title',[_vm._v(" Aceitar Título ")])],1):_vm._e()],1)],1)],1)])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"pa-0",attrs:{"colspan":_vm.headers.length}},[_c('v-list',{attrs:{"color":"grey lighten-4","dense":""}},[_c('list-item',{attrs:{"label":"Valor Original","value":_vm.$n(item.originalValue, 'currency'),"divider":""}}),(item.installmentSelected.tickets.reduce(function (acc, item) { return acc + (item.discount || 0); }, 0))?_c('list-item',{attrs:{"label":"Desconto no Valor Original","value":_vm.$n(item.installmentSelected.tickets.reduce(function (acc, item) { return acc + (item.discount || 0); }, 0), 'currency'),"divider":""}}):_vm._e(),_vm._l((item.installmentSelected.details),function(detail,index){return _c('list-item',{key:index,attrs:{"label":_vm.$t('calculateParams.' + detail.type),"value":_vm.$n(detail.value, 'currency'),"info":_vm.$n(detail.value + detail.discount, 'currency') + ' desconto de: ' + _vm.$n(detail.discount, 'currency'),"divider":index + 1 < item.details.length}})}),_c('list-item',{attrs:{"label":"Valor da Proposta","value":_vm.$n(item.installmentSelected.currentValue, 'currency'),"divider":""}})],2)],1)])]}}],null,false,685509859)}):[(_vm.$can('CaseProposalCreate'))?_c('empty-state',{staticClass:"absolute-center",attrs:{"size":"75px","icon":"handshake","fa-icon":""}},[_vm._v(" Não há propostas "),_c('br'),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"accent","depressed":"","small":""},on:{"click":_vm.createProposal}},[_vm._v(" Criar proposta ")])],1):_vm._e()]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }