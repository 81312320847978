<template>
  <v-container grid-list-lg>
    <loading
      v-if="loading > 0"
      class="absolute-center" />

    <v-container
      v-else
      class="pa-0"
      grid-list-lg>
      <a
        class="back-link"
        @click="$router.push({ name: 'CaseProposalsList' })">
        voltar para propostas <v-icon small>{{ $root.icons.action.previous }}</v-icon>
      </a>

      <v-layout
        row
        wrap
        class="mt-6"
        align-center>
        <v-flex
          xs6
          sm3
          class="px-0">
          <display-field
            :value="installment.parcel"
            label="Parcelas" />
        </v-flex>
        <v-flex
          xs6
          sm5
          class="px-0">
          <display-field
            :value="$n(installment.parcelValue, 'currency')"
            label="Valor das Parcelas" />
        </v-flex>
        <v-flex
          xs6
          sm4
          class="px-0">
          <display-field
            :value="$n(installment.currentValue, 'currency')"
            label="Total" />
        </v-flex>
      </v-layout>

      <h2 class="subtitle-1 my-2 darkGray--text">
        <v-icon
          size="22px"
          class="icon-align-text mr-1"
          color="darkGray">
          {{ $root.icons.layout.label }}
        </v-icon>
        Detalhes
      </h2>

      <v-layout
        row
        wrap>
        <v-flex
          xs6
          sm6
          class="px-0">
          <display-field label="Tipo" />
        </v-flex>
        <v-flex
          xs3
          sm3
          class="px-0">
          <display-field
            class="text-center px-0"
            label="Desconto" />
        </v-flex>
        <v-flex
          xs3
          sm3
          class="px-0">
          <display-field
            class="text-center px-0"
            label="Valor" />
        </v-flex>
      </v-layout>

      <div
        v-for="(detail, index) in installment.details"
        :key="`${index}-details`">
        <v-divider class="mt-2" />
        <v-layout
          row
          wrap
          align-center>
          <v-flex
            xs6
            sm6
            class="px-0">
            <display-field :value="$t('calculateParams.' + detail.type)" />
          </v-flex>
          <v-flex
            xs3
            sm3
            class="px-0">
            <display-field
              suffix="%"
              class="text-center px-0"
              :value="detail.discount.toFixed(2)" />
          </v-flex>
          <v-flex
            xs3
            sm3
            class="px-0">
            <display-field
              class="text-center px-0"
              :value="$n(detail.value, 'currency')" />
          </v-flex>
        </v-layout>
      </div>

      <h2 class="subtitle-1 mt-6 mb-2 darkGray--text">
        <v-icon
          size="22px"
          class="icon-align-text mr-1"
          color="darkGray">
          {{ $root.icons.layout.label }}
        </v-icon>
        Status do pagamento
      </h2>

      <v-layout
        row
        wrap>
        <v-flex
          xs2
          sm2
          class="px-0">
          <display-field
            class="text-center px-0"
            label="Parcela" />
        </v-flex>
        <v-flex
          xs2
          sm2
          class="px-0">
          <display-field
            class="text-center px-0"
            label="Total" />
        </v-flex>
        <v-flex
          xs3
          sm3
          class="px-0">
          <display-field
            class="text-center px-0"
            label="Vencimento" />
        </v-flex>
        <v-flex
          xs3
          sm3
          class="px-0">
          <display-field
            class="text-center px-0"
            label="Status" />
        </v-flex>
        <v-flex
          xs2
          sm2
          class="px-0">
          <display-field
            class="text-center"
            label="Ação" />
        </v-flex>
      </v-layout>

      <div
        v-for="(ticket, index) in ticketsPayments"
        :key="index">
        <v-divider class="mt-2" />
        <v-layout
          row
          wrap
          align-center>
          <v-flex
            xs2
            sm2
            class="px-0">
            <display-field
              class="text-center px-0"
              :value="ticket.parcel" />
          </v-flex>
          <v-flex
            xs2
            sm2
            class="px-0">
            <display-field
              class="text-center px-0"
              :value="$n(ticket.amount, 'currency')" />
          </v-flex>
          <v-flex
            xs3
            sm3
            class="px-0">
            <display-field
              class="text-center px-0"
              :value="ticket.dueAt && ticket.dueAt.toDate ? $d(ticket.dueAt.toDate(), 'short_date') : (ticket.dueAt && ticket.dueAt.split('-').reverse().join('/'))" />
          </v-flex>
          <v-flex
            xs3
            sm3
            class="px-0">
            <display-field
              class="text-center px-0"
              :value="$t('bankSlip.state.' + ticket.state)" />
          </v-flex>
          <v-flex
            xs2
            sm2
            class="px-0 text-center">
            <v-btn
              title="Reenviar boleto por e-mail"
              icon
              small
              color="bgColor"
              class="mini"
              fab
              depressed
              :disabled="ticket.state === 'payed'"
              @click="resend">
              <v-icon
                small
                color="darkGray">
                {{ $root.icons.action.replay }}
              </v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </div>
    </v-container>
  </v-container>
</template>

<script>
  import casesMixin from '@/mixins/cases'
  import { mapGetters } from 'vuex'

  export default {
    mixins: [ casesMixin ],
    data () {
      return {}
    },
    computed: {
      ...mapGetters({
        proposal: 'proposal/record'
      }),
      installment (){
        return this.proposal && this.proposal.installmentSelected || {}
      },
      ticketsPayments (){
        return this.proposal && this.proposal.ticketsPayments || []
      }
    },
    beforeMount () {
      this.$store.dispatch('proposal/get', { proposalId: this.$route.params.proposalId, recalculate: false })
    },
    methods: {
      resend () {
        console.log('resend ticket')
      }
    },
  }
</script>

<style lang="sass">
  .btn.mini
    width: 28px
    height: 28px
</style>
