<template>
  <side-content>
    <template v-slot:header>
      Propostas
      <v-spacer />
      <v-btn
        class="ml-2"
        color="#E5E5EA"
        fab
        depressed
        x-small
        :to="{ name: 'CaseShow' }">
        <v-icon color="primary">
          close
        </v-icon>
      </v-btn>
    </template>

    <loading
      v-if="loading > 0"
      class="absolute-center" />

    <v-layout
      v-else
      row
      wrap
      class="mt-0">
      <v-flex
        xs12
        sm12
        class="py-0 pa-0">
        <v-data-table
          v-if="proposals.length > 0"
          class="elevation-0"
          :headers="headers"
          :items="proposals"
          :single-expand="true"
          :expanded.sync="expanded"
          hide-default-footer>
          <template v-slot:item="{ item, isExpanded }">
            <tr
              title="Exibir detalhes"
              :class="{'table-list-opened': isExpanded }">
              <td class="px-2 caption" @click="toggleExpand(item)">
                {{ $d(item.insertedAt.toDate(), 'date') }}
              </td>
              <td class="px-2 text-center caption" @click="toggleExpand(item)">
                <span
                  v-if="['created', 'visualized'].includes(item.state)">
                  {{ item.daysExpire }} dia(s)
                </span>
                <span
                  v-else>
                  -
                </span>
              </td>
              <td class="px-2 text-center caption" @click="toggleExpand(item)">
                {{ $t('proposal.state.' + item.state) }}
              </td>
              <td class="px-0">
                <v-menu offset-y left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      small
                      class="mx-0"
                      v-on="on">
                      <v-icon
                        size="26px"
                        color="primary">
                        {{ $root.icons.layout.more_y }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item :disabled="ticketsSize === 1" @click="remove(item)">
                      <v-list-item-title>Remover Título</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="canIgnore" :disabled="ticketsSize === 1" @click="change(item, !item.ignoreOnInitialProposal)">
                      <v-list-item-title v-if="!item.ignoreOnInitialProposal">
                        Ignorar Título
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        Aceitar Título
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ item }">
            <tr>
              <td class="pa-0" :colspan="headers.length">
                <v-list color="grey lighten-4" dense>
                  <list-item label="Valor Original" :value="$n(item.originalValue, 'currency')" divider />
                  <list-item v-if="item.installmentSelected.tickets.reduce((acc, item) => acc + (item.discount || 0), 0)" label="Desconto no Valor Original" :value="$n(item.installmentSelected.tickets.reduce((acc, item) => acc + (item.discount || 0), 0), 'currency')" divider />
                  <list-item
                    v-for="(detail, index) in item.installmentSelected.details"
                    :key="index"
                    :label="$t('calculateParams.' + detail.type)"
                    :value="$n(detail.value, 'currency')"
                    :info="$n(detail.value + detail.discount, 'currency') + ' desconto de: ' + $n(detail.discount, 'currency')"
                    :divider="index + 1 < item.details.length" />
                  <list-item label="Valor da Proposta" :value="$n(item.installmentSelected.currentValue, 'currency')" divider />
                </v-list>
              </td>
            </tr>
          </template>
        </v-data-table>
        <template v-else>
          <empty-state
            v-if="$can('CaseProposalCreate')"
            class="absolute-center"
            size="75px"
            icon="handshake"
            fa-icon>
            Não há propostas <br>
            <v-btn
              color="accent"
              class="mt-2"
              depressed
              small
              @click="createProposal">
              Criar proposta
            </v-btn>
          </empty-state>
        </template>
      </v-flex>
    </v-layout>
  </side-content>
</template>

<script>
  import casesMixin from '@/mixins/cases'

  import PromiseTermDownload from '@/components/ui/PromiseTermDownload'
  import { mapGetters } from 'vuex'

  export default {
    components: { PromiseTermDownload },
    mixins: [ casesMixin ],
    data () {
      return {
        expanded: [],
        headers: [
          { text: 'Data', value: 'dueAt', sortable: false, align: 'left' },
          { text: 'Expira', value: 'daysExpire', sortable: false, align: 'center' },
          { text: 'Status', value: 'status', sortable: false, align: 'center' },
          { text: '', value: 'actions', sortable: false, align: 'right' }
        ],
        options: { suppressScrollX: true, wheelPropagation: false }
      }
    },
    computed: {
      ...mapGetters({
        caseRecord: 'cases/record',
        proposals: 'proposal/listByCase',
        loading: 'proposal/listByCaseLoading',
        isNegotiator: 'user/isNegotiator',
        isMediator: 'user/isMediator'
      }),
      showActionButton () {
        const checkCase = this.$can('CaseProposalCreate') && this.proposals.length !== 0
        const canCreate = this.isNegotiator || this.isMediator
        const caseState = this.caseRecord && this.caseRecord.state
        let hasProposal = false
        // TODO fix botao de criacao de proposta
        // this.proposals.forEach(proposal => {
        //   if(['created', 'visualized'].includes(proposal.state)){
        //     hasProposal = true
        //   }
        // })
        // return checkCase && !['accepted', 'refused'].includes(caseState) && canCreate && !hasProposal
        return checkCase && canCreate && !hasProposal
      }
    },
    beforeMount () {
      this.$store.commit('app/setLoading', false)
      this.$store.dispatch('proposal/getListByCase', this.$route.params.caseId)
    },
    methods: {
      toggleExpand(item) {
        this.expanded = this.expanded.includes(item) ? [] : [item]
      }
    }
  }
</script>
